const pl = {
  navMenu: {
    profile: 'Profil mentora',
    logout: 'Wyloguj'
  },
  sidebar: {
    dashboard: "Kokpit",
    applicants: "Moi aplikanci",
    schedules: "Harmonogramy",
    consultations: "Spotkania",
    contentFiles: "Materiały",
    videoChat: "VideoChat",
    insurances: "Ubezpieczenia",
  },
  auth: {
    authFooter: {
      srTitle: 'Stopka strony zawierająca dane kontaktowe, nawigację po stronie i zapisy do newslettera',
      addressColumn: {
        title: 'Educat Sp. z o.o.',
        address1: 'ul. Zygmunta Krasińskiego 2/55',
        address2: '20-709 Lublin, Polska',
        nip: 'NIP: 9662136157',
        regon: 'REGON: 385301298'
      },
      contactColumn: {
        title: 'Kontakt',
        correspondenceAddressTitle: 'Adres korespondencyjny:',
        address1: 'Al. KEN 93 (iDID)',
        address2: '02-777 Warszawa'
      },
      linksColumn: {
        title: 'Educat',
        aboutUs: 'O nas',
        career: 'Kariera',
        cooperation: 'Współpraca',
        contact: 'Kontakt',
        policies: 'Regulamin i polityka prywatności',
        safePayments: 'Bezpieczne płatności',
      },
      newsletterColumn: {
        title: 'Bądź na bieżąco',
        subscribeInfo: 'Subskrybuj nasz newsletter',
        newsletterLabelSR: 'Wpisz swój adres email aby otrzymac newsletter',
        newsletterSubmitButtonSR: 'Przycisk zatwierdzania formularza subskrypcji Newslettera'
      },
      copyright: 'Educat {{ year }} © Wszystkie prawa zastrzeżone.',
      socialIcons: {
        facebook: 'Link prowadzący do profilu na Facebooku',
        instagram: 'Link prowadzący do profilu na Instagramie',
        linkedin: 'Link prowadzący do profilu na Linkedin',
        youtube: 'Link prowadzący do kanalu Youtube',
      }
    },
    formControls: {
      placeholder: 'Wpisz tutaj',
      email: 'Email',
      password: 'Hasło',
      newPassword: 'Nowe hasło',
      repeatPassword: 'Powtórz nowe hasło',
      registrationTermsAgreement: `Logując się do serwisu akceptujesz <a href="https://clinichunter.com/privacy-policy/" target="_blank"> Politykę prywatności</a> oraz <a href="https://clinichunter.com/clinics-terms/" target="_blank"> Regulamin </a>. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
      phone: 'Telefon',
      firstName: 'Imię',
      lastName: 'Nazwisko',
    },
    register: {
      title: 'Zarejestruj się',
      subtitle: 'Platforma EduCat to miejsce ułatwiające proces przygotowania do studiów. Jeśli chcesz dołączyć do grona naszych mentorów, wypełnij poniższy formularz. W kolejnych krokach poprosimy Cię o uzupełnienie Twoich danych na temat Twoich doświadczeń, a następnie zaprosimy Cię na rozmowę z naszym rekruterem.',
      haveAccount: 'Masz już konto w naszym serwisie? ',
      logIn: 'Zaloguj się.',
      registerWith: {
        title: 'lub zarejestruj się bezpośrednio za pomocą swojego konta na:',
        facebook: 'Zarejestruj się przez Facebook',
        google: 'Zarejestruj się przez Google'
      }
    },
    confirmRegistration: {
      title: "Potwierdź rejestrację",
      subtitle: "Aby zakończyć rejestrację nacisnij przycisk poniżej."
    },
    login: {
      title: 'Zaloguj się',
      subtitle: 'To wspaniałe, że chcesz pomóc spełniać marzenia Twoim młodszym kolegom i koleżankom. Zaloguj się na Platformę, aby móc pracować z Aplikantami.',
      haveAccount: 'Chcesz dołączyć do grona naszych Mentorów? ',
      register: 'Zarejestruj się.',
      resetPassword: 'Nie pamiętasz hasła?',
      loginWith: {
        title: 'lub zaloguj się bezpośrednio za pomocą swojego konta na:',
        facebook: 'Zaloguj się przez Facebook',
        google: 'Zaloguj się przez Google'
      }
    },
    resetPassword: {
      title: 'Zresetuj hasło',
      subtitle: 'Nie martw się, że hasło Ci umknęło :) Wpisz adres email, który został użyty przy rejestracji. Wyślemy na niego dalsze instrukcje.'
    },
    newPassword: {
      title: 'Zmień hasło',
      subtitle: 'Wpisz nowe hasło i kontynuuj przygodę na Platformie EduCat'
    },
    alert: {
      loginSuccess: "Logowanie zakończone pomyślnie.",
      loginFailed: "Niepoprawny login lub hasło. Spróbuj jeszcze raz.",
      logout: "Zostałeś wylogowany.",
      registrationSuccess: "Dane rejestracyjne wysłane prawidłowo, potwierdź rejestrację klikając w link podany w emailu.",
      confirmRegistrationSuccess: "Rejestracja została potwierdzona, zaloguj się za pomocą maila i swojego hasła.",
      sendResetPasswordMailSuccess: "Na twój adres email wysłaliśmy wiadomość z linkiem resetującym hasło.",
      confirmResetPasswordSuccess: "Twoje hasło zostało zmienione, możesz zalogować się za jego pomocą.",
      loginRequired: "Musisz być zalogowany aby mieć dostęp do danych.",
      invalidConfirmRegistrationURL: "Nie poprawny link potwierdzający rejestrację.",
      invalidResetPasswordURL: "Nie poprawny link potwierdzający zmianę hasła.",
      authenticationError: 'Musisz być zalogowany aby mieć dostęp do danych.',
      generalError: 'Przepraszamy, wystąpił nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie. Jeżeli błąd będzie nadal się pojawiał skontaktuj się z nami pod adresem: info@educat.study.'
    }
  },
  mentorOnboarding: {
    registration: {
      study_information: {
        title: "1 krok: Informacje o studiach",
        formControls: {
          studyFieldNotFound: 'Brakuje mojego kierunku',
          studyLevel: {
            label: 'Poziom studiów',
            bachelor: 'Licencjackie',
            master: "Magisterskie"
          },
          removeStudyInformation: 'Usuń kierunek',
          studyCountry: {
            label: 'Kraj studiów',
          },
          school: {
            label: 'Uczelnia',
          },
          realm: {
            label: 'Dziedzina #{{index}}',
          },
          fieldOfStudy: {
            label: 'Kierunek #{{index}}',
          },
          status: {
            label: "Status",
            student: "Student",
            graduate: "Absolwent",
            yearOfStudy: "rok studiów"
          },
          placeholder: "Wybierz z listy"
        },
        addButton: "Dodaj kolejny kierunek"
      },
      help_range: {
        title: "2 krok: Zakres pomocy",
        additionalInfo: "Zaznacz, w których kategoriach jesteś w stanie pomóc w aplikacji studia według schematu:",
        formControls: {
          generalConversation: "Ogólna rozmowa o pomocy mentorskiej ",
          documentPreparation :"Przygotowanie potrzebnych dokumentów aplikacyjnych" ,
          applicationDocumentCheck:"Sprawdzenie poprawności dokumentów aplikacyjnych ",
          materialPreparation:"Przygotowanie zakresu materiałów potrzebnych w procesie aplikacyjnym",
          timetablePreparation:"Stworzenie harmonogramu aplikacji na wybrany kierunek ",
          documentVerification:"Sprawdzenie poprawności złożonej aplikacji w systemie aplikacyjnym uczelni / pomoc w dodaniu dokumentów",
          interviewPreparation:"Przygotowanie do rozmowy kwalifikacyjnej" ,
          applicationExamPreparation: {
            title: "Przygotowanie do egzaminu aplikacyjnego: (zaznacz poszczególne)",
            tsa: "TSA",
            fineArt: "Fine art practical",
            bmat: "BMAT",
            ucat: "UCAT",
            cat: "CAT",
            elat: "ELAT",
            hat: "HAT",
            mat: "MAT",
            bocconi: "Test Bocconi",
            lnat: "LNAT",
            ompt: "OMPT",
            nsaa: "NSAA",
            sat: "SAT",
            step: "STEP",
            engaa: "ENGAA",
            mlat: "MLAT",
            olat: "OLAT",
            imat: "IMAT",
            pat: "PAT",
            philosophy: "Philosophy Test",
            musicPerformance: "Music performance test",
            stGallen: "Test St. Gallen",
          },
          afterApplicationHelp: "Pomoc poaplikacyjna",
          helpRealms: "Dziedziny, w których możesz pomóc",
          placeholder: "Wybierz z listy"
        }
      },
      basic_information: {
        title: "3 krok: Informacje podstawowe",
        formControls: {
          displayName: "Nazwa wyświetlana dla innych",
          birthDate:"Data urodzenia",
          address: "Adres korespondencyjny",
          languages: {
            label: "Język prowadzenia zajęć",
            placeholder: "Wpisz tutaj, oddzielając przecinkiem"
          },
          photo: {
            label: "Zdjęcie",
            input: "Dodaj zdjęcie z dysku"
          },
          inputPlaceholder: "Wpisz tutaj",
          datePlaceholder: "DD / MM / RRRR"
        }
      },
      mentor_description: {
        title: "4 krok: Opis mentora",
        formControls: {
          longDescription: "O mnie",
          programme: "Mój styl pracy",
          checkbox: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
          placeholder: "Wpisz tutaj"
        }
      },
      classes: {
        title: "5 krok: Korepetycje",
        formControls: {
          yes: "tak",
          no: "no",
          subjectClasses: "Czy chcesz prowadzić także korepetycje przedmiotowe?",
          selectedSubject: "Wybrany przedmiot",
          subjectClassesPlaceholder: "Wybierz przedmiot z listy",
          maturityExam: {
            title: "Matura",
            basic: "matura podstawowa",
            extended: "matura rozszerzona",
            international: "matura międzynarodowa IB",
            math: {
              analysisASL: "matematyka Analysis and Approaches SL",
              applicationsISL: "matematyka Applications and Interpretations SL",
              analysisAHL: "matematyka Analysis and Approaches HL",
              applicationsIHL: "matematyka Applications and Interpretations HL"
            },
            biology: {
              biologyHL: "biologia HL",
              biologySL: "biologia SL"
            },
            chemistry: {
              chemistryHL: "chemia HL",
              chemistrySL: "chemia SL"
            },
            physics: {
              physicsHL: "fizyka HL",
              physicsSL: "fizyka SL"
            },
            history: {
              historyHL: "historia HL",
              historySL: "historia SL"
            },
            geography: {
              geographyHL: "geografia HL",
              geographySL: "geografia SL"
            },
            french: {
              frenchHL: "francuski HL",
              frenchSL: "francuski SL"
            },
            italian: {
              italianHL: "włoski HL",
              italianSL: "włoski SL"
            },
            russian: {
              russianHL: "rosyjski HL",
              russianSL: "rosyjski SL"
            },
            spanish: {
              spanishHL: "hiszpański HL",
              spanishSL: "hiszpański SL"
            },
            computerScience: {
              computerScienceHL: "informatyka HL",
              computerScienceSL: "informatyka SL"
            },
            english: {
              englishHL: "angielski HL",
              englishSL: "angielski SL"
            },
          },
          exams: {
            title: "Egzamin",
            tsa: "TSA",
            fineArt: "Fine art practical",
            bmat: "BMAT",
            ucat: "UCAT",
            cat: "CAT",
            cae: "CAE",
            cpe: "CPE",
            celi345: "CELI 3, 4, 5",
            ctmua: "CTMUA",
            dsd1: "DSD 1",
            dsd2: "DSD 2",
            delf: "DELF",
            dele: "DELE",
            testDaf: "Test DaF",
            elat: "ELAT",
            engaa: "ENGAA",
            gmat: "GMAT",
            gre: "GRE",
            hat: "HAT",
            ielts: "IELTS",
            mat: "MAT",
            bocconi: "Test Bocconi",
            lnat: "LNAT",
            ompt: "OMPT",
            pearson: "PEARSON",
            nsaa: "NSAA",
            sat: "SAT",
            step: "STEP",
            step123: "STEP 1,2,3",
            tmua: "TMUA",
            toefl: "TOEFL",
            mlat: "MLAT",
            olat: "OLAT",
            imat: "IMAT",
            ukat: "UKAT",
            pat: "PAT",
            philosophy: "Philosophy Test",
            musicPerformance: "Music performance test",
            stGallen: "Test St. Gallen",
            torfl: "TORFL",
            haa: "HAA (Cambridge)"
          },
          additional: {
            title: "Pozostałe",
            primarySchoolExam: "egzamin 8-klasisty",
            middleSchool: "szkoła średnia",
            olympicsPreparations: "przygotowania do olimpiady",
            nativeSpeaker: "native speaker",
            conversations: "konwersacje",
            languageExams: "egzaminy językowe",
            beginner: "poziom A1-A2 (Begginer, Elementary)",
            intermediate: "poziom B1-B2 (Intermediate)",
            advanced: "poziom C1-C2 (Advanced, Proficency)",
          }
        }
      },
      thank_you: {
        title: "Dziękujemy za wypełnienie",
        info: 'Twoje zgłoszenie zostało do nas wysłane. Następny krok to rozmowa z naszym rekruterem. W ciągu najbliższych dwóch dni roboczych odezwiemy się do Ciebie, aby umówić się na spotkanie online i porozmawiać o Twoim doświadczeniu i wyjaśnić zasady współpracy na Platformie EduCat. Do usłyszenia!'
      },
      alerts: {
        registrationSendingFailure: "Wystąpił błąd podczas wysyłania ankiety rejestracyjnej, spróbuj ponownie albo skontaktuj się z Administratorem",
        registrationSendingSuccess: "Ankieta rejestracyjna została wysłana. Po zatwierdzeniu rejestracji przez Administratora zostanie wysłany email z potwierdzeniem dostępu do następnych kroków.",
        fetchMentorError: "Pobranie danych użytkownika nie powiodło się, spróbuj ponownie albo skontaktuj sie z Administratorem."
      }
    },
    onboarding: {
      maxApplicantsAdditionalInformation: "* maksymalna liczba aplikantów do przyjęcia w ramach pakietu",
      priceRecalculatingInfo: "* cena podana we wcześniejszym kroku za godzinę próbną automatycznie nalicza cenę za pakiety",
      formTableHeader: {
        helpRange: "Zakres pomocy / usługi",
        eduCatPrice: "Cena netto na Platformie EduCat",
        hourPackages: "Pakiety godzin",
        nettoWithoutDiscount: "Cena netto bez rabatu*",
        clientDiscount: "Rabat dla klienta",
        nettoAfterDiscount: "Cena netto po rabacie",
        packages: "Pakiety",
        servicesInPackage: "Usługi w ramach pakietu",
        maxApplicantNo: "Max. liczba aplikantów*",
        eduCatCommission: "Prowizja EduCat",
        mentorGrossPrice: "Zarobek brutto Mentora w PLN",
        vat: "Podatek Vat",
        platformPrice: "Cena na platformie"
      },
      confirmation_page: {
        title: "Ankieta onboardingowa",
        registrationBasic: {
          title: "Oczekiwanie na zatwierdzenie rejestracji",
          confirmInformation: "Twoja rejestracja jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem umożliwiający jej kontynuowanie."
        },
        registration: {
          title: "Oczekiwanie na zatwierdzenie rejestracji przez administratora",
          confirmInformation: "Twoja rejestracja uzupełniająca jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem."
        },
        onboarding: {
          title: "Oczekiwanie na zatwierdzenie onboardingu",
          confirmInformation: "Twoja ankieta onboardingowa jest w trakcie procesu akceptacji. Po zatwierdzeniu przez Administratora, zostanie wysłany e-mail z potwierdzeniem."
        }
      },
      onboarding_information: {
        title: "Ankieta onboardingowa",
        formTitle: "Informacje Ogólne",
        additionalInfo: "Aby rozpocząć współpracę, potrzebujemy od Ciebie kilku informacji do celów podatkowych, aby móc w przyszłości się z Tobą rozliczać za przeprowadzone usługi. Odpowiedz na poniższe pytania, a w razie wątpliwości daj nam znać!",
        formControls: {
          placeholder: 'Wpisz tutaj',
          nationality: {
            label: "Jakiej jesteś narodowości?",
            polish: "Polskiej",
            other: "Innej"
          },
          residency: {
            label: "Czy jesteś rezydentem podatkowym innego kraju niż Polska?",
            yes: "Tak",
            no: "Nie",
            notSure: "Nie wiem"
          },
          businessOwner: {
            label: "Czy masz działalność gospodarczą?",
            yes: "Mam działalność",
            no: "Nie mam działalności"
          },
          over26: {
            label: "Czy masz 26 lat lub wyżej?",
            overOrEqual: "26 lat lub więcej",
            under: "poniżej 26 lat"
          },
          student: {
            label: "Czy jesteś studentem?",
            yes: "Jestem studentem",
            no: "Nie jestem studentem"
          },
          otherEmployment: {
            label: "Inne miejsca zatrudnienia"
          },
          bankDataLabel: "Podaj numer swojego konta bankowego, na które chciałbyś dostawać swoją pensję.",
           bankAccountNumber: {
            label: "Nr konta"
          },
           bankData: {
            label: "Dane banku"
          },
        }
      },
      hourly_rates: {
        title: "Ankieta onboardingowa",
        formTitle: "Stawki za godziny",
        formSubtitle: "W zależności od tego, jakie usługi zaznaczyłeś w ankiecie rejestracyjnej, podaj jakie stawki chcesz ustalić za poszczególne usługi. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
        tooltipLabel: "Szacowana czasochłonność danej usługi wynosi {{expectedLength}}",
        tooltip: "Godzina możliwa do wykupienia przez Aplikanta tylko raz z danym mentorem. Jest to okazja do zareklamowania swoich dalszych usług",
        name: {
          'consultation_trial': "Godzina Próbna",
          'consultation': "Konsultacja"
        }
      },
      service_packages: {
        title: "Ankieta onboardingowa",
        formTitle: "Pakiety usług",
        formSubtitle: "Na platformie Klienci mają dostęp do pakietów wielu usług z rabatami 15% Zaznacz, które pakiety usług chcesz wykonywać. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
      },
      hour_packages: {
        title: "Ankieta onboardingowa",
        formTitle: "Pakiety godzin",
        formSubtitle: "Na platformie Klienci mają dostęp do pakietów godzin z danym mentorem. Zaznacz, które pakiety godzin chcesz wykonywać. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
      },
      free_help_packages: {
        title: "Ankieta onboardingowa",
        formTitle: "Pakiet pomocy bezpłatnej",
        formSubtitle: `Pomoc bezpłatna obejmuje przygotowanie do aplikacji tylko na uczelnie partnerskie EduCat. W ramach pakietu pomocy bezpłatnej jest 5 godzin z mentorem.
        Co dostajesz za pracę z aplikantami korzystającymi z pomocy bezpłatnej?

        - uczestnictwo w szkoleniach
        - doświadczenie zawodowe w pracy z klientem
        - społeczność międzynarodowych studentów EduCat
        - dodatkowe zarobki (jeśli Twój aplikant dostanie się i wyjedzie na studia na uczelni partnerskiej)

        Zaznacz, czy chcesz wykonywać ten pakiet oraz ilu aplikantów bezpłatnych chcesz maksymalnie przyjąć pod opiekę w danym roku aplikacyjnym (od września do września).
        `,
      },
      application_exam_prices: {
        title: "Ankieta onboardingowa",
        formTitle: "Przygotowanie do egzaminu aplikacyjnego",
        formSubtitle: "W zależności od tego, jakie usługi zaznaczyłeś w ankiecie rejestracyjnej, podaj jakie stawki chcesz ustalić za poszczególne usługi. EduCat pobiera prowizję 60%, która konieczna jest ze względu na utrzymanie działania platformy.",
      },
      thank_you: {
        title: "Dziękujemy za wypełnienie!",
        line1:
              "Twoja ankieta do nas dotarła. Teraz przygotowujemy dla Ciebie umowę, którą otrzymasz od nas poprzez system DocuSign. Po jej podpisaniu dostaniesz pełny dostęp do Platformy wraz z instrukcją jej obsługiwania.",
        line2: "Zwykle przygotowanie umowy zajmuje nam nie więcej niż 2 dni robocze :)",
        greeting: "Do usłyszenia!",
        signature: "Zespół EduCat",
      },
      alerts: {
        onboardingSendingFailure: "Wystąpił błąd podczas wysyłania ankiety onboardingowej, spróbuj ponownie albo skontaktuj się z Administratorem",
        onboardingSendingSuccess: "Dane podatkowe użytkownika zostały wysłane.",
        fetchMentorError: "Pobranie danych użytkownika nie powiodło się, spróbuj ponownie albo skontaktuj sie z Administratorem.",
        onboardingPackagePricesCalculationFailure: "Przeliczenie cen pakietów się nie powiodło, spróbuj ponownie albo skontaktuj się z Administratorem",
        onboardingPackagePricesCalculationSuccess: "Przeliczono ceny pakietów",
        createServiceDefinitionFailure: "Nie udało się zapisać zmian, spróbuj ponownie lub skontaktuj się z Administratorem",
        createServiceDefinitionSuccess: "Zmiany zapisane poprawnie.",
      },
    },
  },
  profile: {
    srTitle: "Profil Użytkownika",
    changePasswordForm: {
      title: "Zmień hasło",
      controls: {
        currentPassword: "Twoje obecne hasło",
        newPassword: "Nowe hasło",
        repeatNewPassword: "Powtórz nowe hasło",
        placeholder: "Wpisz tutaj",
      },
      passwordChanged: "Twoje hasło zostało pomyślnie zmienione."
    },
    video: {
      title: "Moja wideo prezentacja",
      deleteVideo: "Usuń wideo",
      addVideo: "Dodaj nową prezentację",
      noVideo: "Nie masz dodanych wideo",
      videoNotSupported: "Twoja przeglądarka nie obsługuje wideo HTML5.",
      videoLink: "Adres URL do filmu",
      videoLinkPlaceholder: "Wpisz adres URL"
    },
    profileInfo: {
      srTitle: "Karta detali profilu",
      languages: "Języki:",
      studyLevel: {
        master: 'Magister',
        bachelor: 'Student'
      },
      studyLength: 'rok',
      description: 'Opis',
      realms: 'Dziedziny',
      workDescription: 'Mój styl pracy',
      addNewRealm: 'Dodaj nową dziedzinę',
      select: 'Wybierz'
    },
    services: {
      servicesRates: {
        title: 'Stawki za usługi',
        statuses: {
          notActive: 'Nieaktywna',
          processing: 'W trakcie zmiany'
        },
      },
      packagesRates: {
        title: 'Stawki za pakiety'
      },
      tooltip: {
        notActiveText: 'Nie wybrałeś tej usługi do prowadzenia.',
        activeText: 'Stawka zawiera w sobie Twoją cenę brutto, prowizję EduCat oraz 23% VAT.',
        processingText: 'Pakiet / usługa jest w trakcie zmainy',
        requestChange: 'Kliknij jeśli chciałbyś zgłosić zmiany'
      },
      modal: {
        activateService: {
          title: 'Potwierdź chęć włączenia:',
          description: 'Cieszymy się, że chcesz poszerzyć ofertę swoich usług. Daj nam chwilę, przygotujwmy dla Ciebie aneks do umowy i skontaktujemy za pośrednictwem email.',
          link: 'Regulamin zmiany stawek',
          registrationTermsAgreement: `Akceptuję <a href="#" target="_blank"> Regulamin zmiany stawek</a>`,
        },
        changeServicePrice: {
          title: 'Zmiana stawki:',
          description: 'Jeśli nie odpowiadają Ci stawki zaproponowane przez nas w tablece, masz możliwość zmiany kwot za poszczególne pakiety / usługi. Pamiętaj jednak, że wiąże się to z koniecznością lorem ipsum dolor sit amet.',
          deactivateDescription: 'Jeśli chcesz dezaktywować pakiet / usługę, ustaw kwotę na zero.',
          link: 'Regulamin zmiany stawek',
          registrationTermsAgreement: `Akceptuję <a href="#" target="_blank"> Regulamin zmiany stawek</a>`,
        },
        form: {
          labels: {
            amount: "Jaką kwotę brutto chciałbyś otrzymywać?"
          },
          placeholders: {
            inputPlaceholder: "Wpisz tutaj",
          },
          btn: "Wyślij zgłoszenie"
        },
        successAlert: "Zgłoszenie zostało wysłane."
      }
    },
    profileForm: {
      profileUpdated: "Dane konta zostały pomyślnie zaktualizowane."
    }
  },
	dashboard: {
      header: {
        title: "Cześć",
        description: "Platforma EduCat została stworzona, jako miejsce przyjazne nie tylko uczniom korzystającym z usług na niej dostępnych, ale też mentorom. Życzymy Ci powodzenia w pracy i samych sukcesów z aplikantami. Jeśli masz jakiekolwiek uwagi - skontaktuj się z nami na info@educat.study. Cieszymy się, że z nami jesteś! Powodzenia!",
        signature: "Zespół EduCat"
      },
		applicants: {
			title: "Twoi aplikanci",
			redirectBtn: "Przejdź do listy aplikantów",
			application: "Aplikuje na:",
		},
		eventCalendar: {
			title: "Twoje wydarzenia",
			noEvents: "Nie masz planów na ten dzień",
		},
		fieldOfStudy: {
			title: "Wybrane kierunki",
			schoolDetails: {
				rankingNo: "nr",
				ranking: "w rankingu",
			},
		},
		schedule: {
			title: "Harmonogram aplikacji",
			controls: {
				week: "Tydzień",
				month: "Miesiąc",
				year: "Rok",
			},
		},
		tasks: {
			title: "Zadania aplikanta",
		},
		contentFiles: {
			title: "Materiały aplikanta",
		},
	},
	applicants: {
		title: "Moi aplikanci",
		filters: {
			all: "Wszystkie",
			open: "Otwarte",
			ended: "Zakończone",
		},
		search: "Wyszukaj",
		table: {
          applicant: "Aplikant",
          assistance: "Zakres pomocy",
          hours: "Ilość godzin",
          noData: "Brak dodanych aplikantów",
          range: {
            paid: "Płatna",
            free: "Bezpłatna",
            unknown: "--",
          },
		},
		status: {
			open: "Otwarta",
			ended: "Zakończona",
		},
        applicantTasks: {
          table: {
            noData: "Nie znaleziono żadnych zadań"
          }
        },
		applicantView: {
			information: {
				title: "Informacje o aplikancie",
				highSchool: "Liceum",
				country: "Kraj",
				studyField: "Kierunek",
				school: "Uczelnia",
				studyLevel: "Poziom studiów",
				baccalaureateType: "Rodzaj matury",
				baccalaureateSubjects: "Przedmioty zdawane na maturze",
				studyStartYear: "Rok rozpoczęcia studiów",
				polish_baccalaureate: "Polska matura",
				international_baccalaureate: "IB (Matura międzynarodowa)",
				custom: "Inne",
				notSelected: "Nie wybrano",
                baccalaureateLevel: {
                  primary: "Podstawowy",
                  extended: "Rozszerzony"
                }
			},
			studyLevel: {
				bachelor: "Licencjackie",
				master: "Magisterskie",
			},
			schedules: {
				date: "Dziś:",
			},
			contentFiles: {
				table: {
					documentName: "Nazwa dokumentu",
					creationDate: 'Data dodania',
					addingUser: "Dodający",
					editRemove: "Edytuj/Usuń",
					actionButtons: {
						edit: "Edytuj plik",
						remove: "Usuń plik",
						download: "Pobierz plik",
					},
					noData: "Aplikant nie posiada żadnych materiałów",
					retrieveDataError: "Wystąpił błąd przy pobieraniu materiałów, spróbuj ponownie.",
				},
				buttons: {
					addList: "Dodaj z listy",
					addNew: "Dodaj nowy",
				},
			},
			tasks: {
				title: "Zadania aplikanta",
				table: {
					title: "Nazwa zadania",
					deadline: "Deadline",
					status: "Status",
					grade: "Ocena",
					mentor: "Kto zadał",
				},
				moreTasks: "Zobacz więcej zadań",
				addNew: "Dodaj nowe",
              addTaskModal: {
				  title: "Dodaj nowe zadanie",
                form: {
                  labels: {
                    taskTitle: "Temat zadania",
                    taskDescription: "Opis zadania",
                    addDocument: "+ Dodaj dokument",
                    deadline: "Deadline",
                    needsReview: "BEZ OCENY"
                  },
                  placeholders: {
                    inputPlaceholder: "Wpisz tutaj",
                    datePlaceholder: "DD / MM / RRRR"
                  }
                },
                addTask: "Dodaj zadanie",
                taskAdded: "Zadanie zostało pomyślnie dodane"
              },
              statusChangeSuccess: 'Status zadania został pomyślnie zmieniony',
              gradeChangeSuccess: 'Ocena została pomyślnie zmieniona'
			},
		},
	},
  newApplicants: {
    title: "Masz nowego aplikanta",
    serviceName: "Wykupiona usługa:",
    serviceDate: "Najbliższe spotkanie:"
  },
	schedules: {
		title: "Harmonogramy",
		filters: {
			country: "Kraj",
			school: "Uczelnia",
			realm: "Kierunek",
		},
		addSchedule: {
			title: "Nowy harmonogram",
			description: "Stwórz nowy harmonogram na uczelnię i kierunek, którego potrzebujesz. Zadbaj o to, aby wszystkie daty były poprawne.",
			add: "Dodaj nowy",
          form: {
			  placeholders: {
                allOptions: 'Wszystkie',
                applicant: 'Aplikant',
                typeOption: 'Zacznij wpisywać nazwę'
              },
            labels: {
              country: 'Kraj',
              school: 'Uczelnia',
              studyField: 'Kierunek'
            }
          }
		},
        noSchedule: {
          title: "Nie znaleziono harmonogramów",
          description: "Niestety, na wybrany przez Ciebie kierunek nie ma jeszcze harmonogramu. Musisz stworzyć go od zera. Sprawdź dokładnie wszystkie terminy związane z aplikacją, aby żaden ważny termin Wam nie uciekł."
        },
		editSchedule: "Edytuj harmonogram",
		addScheduleModal: {
			title: "Dodaj harmonogram",
			preview: "Podgląd",
			previewTitle: "Podgląd harmonogramu {{school}}",
			previewDescription: "Przewijaj harmonogram horyzontalnie aby zobaczyć więcej.",
			backToEdit: "Powrót do edycji",
			accept: "Potwierdź",
			addEvent: "+ Dodaj wydarzenia",
            event: 'Wydarzenie',
            eventStart: 'Początek',
            eventEnd: 'Koniec',
            scheduleAdded: 'Harmonogram został dodany',
            scheduleDetailsUpdated: 'Szczegóły harmonogramu zostały zaktualizowane'
		},
		table: {
			country: "Kraj",
			school: "Uczelnia",
			realm: "Kierunek",
			moreResults: "Zobacz więcej zadań",
		},
		noData: "Nie znaleziono harmonogramów",
		noDataInfo:
			"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
		details: {
			title: "Harmonogram",
			table: {
				events: "Wydarzenia",
				start: "Początek",
				end: "Koniec",
				delete: "Usuń",
			},
			assignToApplicant: {
				assignBtn: "Przypisz do aplikanta",
				confirm: "Potwierdź",
				title: "Przypisz harmonogram do aplikanta {{school}}",
				labels: {
					applicant: "Aplikant",
					addToEducat: "Dodaj zaktualizowany plan dla {{school}} na kierunek ABC {{studyField}} do bazy EduCat",
				},
                scheduleAssignedToApplicant: 'Harmonogram został przypisany do aplikanta'
			},
			edit: "Edytuj",
		},
	},
  contentFiles: {
    srTitle: 'Dostępne materiały aplikantów oraz pole dodawania nowych plików',
    applicantMaterials: {
      title: 'Materiały aplikanta',
      table: {
        documentName: 'Nazwa dokumentu',
        creationDate: 'Data dodania',
        addingUser: 'Dodający',
        editRemove: 'Edytuj/Usuń',
        assignApplicant: 'Dodaj do aplikanta',
        assignToApplicant: 'Dodaj do aplikanta',
        assignInfo: 'Aby dodać dokument do Aplikanta zaznacz nazwisko, a następnie kliknij Potwierdź. Dodany dokument możesz edytować razem z aplikantem poprzez materiały dostępne na jego Profilu.',
        addContentFile: 'Dodaj dokument',
        addContentFileDescription: 'Aby dodać dokument zaznacz nazwę, a następnie kliknij Potwierdź.',
        actionButtons: {
          edit: 'Edytuj plik',
          remove: 'Usuń plik',
          download: 'Pobierz plik',
          assign: 'Dodaj',
          confirmAssign: 'Potwierdź'
        },
        noData: 'Nie znaleziono żadnych materiałów',
        retrieveDataError: 'Wystąpił błąd przy pobieraniu materiałów, spróbuj ponownie.',
        fileAddedSuccess: 'Materiły zostały dodane',
        fileRemoveSuccess: 'Plik został usunięty',
        fileRemoveError: 'Wystąpił błąd podczas usuwania pliku, spróbuj ponownie.',
        materialsAssigned: 'Materiły zostały przypisane do aplikanta'
      }
    },
    addMaterials: {
      title: 'Dodaj nowy',
      dropHere: 'Upuść plik tutaj lub kliknij przycisk',
      uploadSuccess: 'Wysyłanie pliku zakończone sukcesem, aby przesłać kolejny przeciągnij go tu, lub użyj przycisku poniżej. ',
      addNew: 'Dodaj nowy',
      uploadingMessage: 'Ładowanie pliku na serwer, proszę czekać...',
      uploadProgress: 'Przesłano {{progressValue}} ze 100 procent'
    }
  },
  insurances: {
    title: 'Ubezpieczenia',
    newInsurance: 'Nowe ubezpieczenie',
    table: {
      insuranceNumber: 'Numer polisy',
      insuranceDate: 'Data wygenerowania wniosku',
      paymentDate: 'Data zakupu',
      insuranceDuration: 'Okres polisy (rozpoczęcie i koniec)',
      status: 'Status',
      actions: 'Akcje',
    },
    insuranceDownloaded: 'Ubezpieczenie zostało pobrane.',
    insurancePreviewDownloaded: 'Ubezpieczenie zostało otwarte w nowej karcie',
    insuranceUpdated: 'Szczegóły ubezpieczenia zostały zmienione.',
    offerStatus: {
      status_paid: 'Paid',
      status_active: 'Active',
      status_expired: 'Expired',
      status_expired_draft: 'Expired draft',
      status_modified: 'Modified',
      status_draft: 'Draft',
    },
    tooltips: {
      edit: 'Edytuj ubezpieczenie',
      view: 'Podgląd ubezpieczenia',
      download: 'Pobierz ubezpieczenie',
      resume: 'Wróć do wniosku',
    },
    noData: 'Nie dodano żadnych ubezpieczeń.',
    editInsuranceModal: {
      title: 'Edytuj ubezpieczenie',
      formControls: {
        labels: {
          firstName: 'Imię',
          lastName: 'Nazwisko',
          email: 'Email',
          birthDate: 'Data urodzenia',
          status: 'Typ studiów',
          school: 'Uczelnia',
        },
        placeholders: {
          firstName: 'Wpisz tutaj',
          lastName: 'Wpisz tutaj',
          birthDate: 'Wybierz datę',
          email: 'Wpisz tutaj',
          school: 'Wpisz nazwę',
        },
        statuses: {
          bachelor_degree_studies: 'Licencjackie',
          engineering_studies: 'Inżynierskie',
          master_degree_studies: 'Magisterskie',
          doctoral_studies: 'Doktoranckie',
          mba_studies: 'MBA',
          high_school_abroad: 'Liceum za granicą',
          erasmus_or_exchange: 'Erasmus lub wymiana',
          other: 'Inne',
        },
        buttons: {
          cancel: 'Anuluj',
          save: 'Zapisz',
        }
      }
    }
  },
	consultations: {
		title: "Moje spotkania",
		noData: "Nie znaleziono spotkań",
		table: {
			applicant: "Aplikant",
			package: "Pakiet",
			service: "Usługa",
			status: "Status",
			meetingsNo: "Ilość spotkań",
			meetings: "Spotkania",
		},
      form: {
		labels: {
		  from: 'od',
          to: 'do'
        },
        placeholders: {
		  search: 'Wyszukaj'
        }
      },
		applicationStatuses: {
			open: "Otwarta",
			closed: "Zakończona",
		},
		meetingEnded: "Spotkanie zakończone",
		startMeeting: "Rozpocznij",
        joinMeeting: "Dołącz",
        endMeeting: "Zakończ",
		selectDate: " Wybierz datę i godzinę spotkania",
		cancelMeetingTooltip: "Odwołaj spotkanie",
		cancelMeetingTitle: "Czy napewno chcesz odwołać spotkanie",
		meetingCanceled: "Spotkanie zostało odwołane",
      consultationTimeTooltip: 'Zegar uruchomi się na 10 minut przed definitywnym zakończeniem spotkania. Po upłynięciu czas nie będzie możliwości powrotu do rozmowy, dlatego zakończ ją w odpowiednim czasie.'
	},
    videoChat: {
        consultationStart: "Twoja konsultacja zaczyna się o ",
        consultationTimeZone: "Strefa czasowa: czas wschodnioeuropejski",
        startConsultation: "Pamiętaj, aby kliknąć przycisk „Rozpocznij konsultację”, aby dołączyć do konsultacji.",
        noAccess: "Nie możesz dołączyć do tej konsultacji.",
        contactAdmin: "Sprawdź swój link URL lub skontaktuj się z administratorem.",
        consultationEnded: "Spotkanie już się skończyło",
        rescheduleConsultation: "W przypadku nieobecności na spotkaniu prosimy o kontakt z adminem.",
        settings: {
            title: "Ustawienia",
          select: "Wybierz",
            selectCamera: "Wybierz kamerę",
            selectSpeaker: "Wybierz głośnik",
            selectMicrophone: "Wybierz mikrofon",
            testMicrophone: "Powiedz coś żeby sprawdzić mikrofon",
            poor: "poor",
            excellent: "excellent",
            saveChanges: "Zapisz ustawienia"
        },
      chat: {
        placeholder: "Napisz wiadomość",
        noMessages: "Nie masz żadnych wiadomości",
        status: {
          online: 'Online'
        }
      }
    },
    chat: {
      noNodeConnection: "Serwer czatu jest niedostępny. Sprawdź ponownie później",
      noConnectionReasons: "Możliwe przyczyny są wymienione poniżej:",
      not_authorized: "Podano nieprawidłowy token. Spróbuj zalogować się ponownie",
      'websocket error': "Możliwe są inne powody",
      already_online: "Inny użytkownik zalogował się na to konto. Wyloguj się na innych urządzeniach",
      no_token: "Nie podano tokena. Spróbuj zalogować się ponownie",
      expired_token: "Dostarczono wygasły token. Spróbuj zalogować się ponownie",
    },
	footer: {
		copyright: "Educat {{ year }} © Wszelkie prawa zastrzeżone",
	},
	breadcrumbs: {
		dashboard: "Dashboard",
		applicants: "Moi aplikanci",
		meetings: "Spotkania",
		schedules: "Harmonogramy",
		consultations: "Moje spotkania",
		contentFiles: "Materiały aplikanta",
		videoChat: "VideoChat",
	},
	buttons: {
		more: "Zobacz więcej",
		book: "Rezerwuj",
		searchRedirect: "Idź do wyszukiwarki",
		buyApplication: "Wykup aplikację",
		takeTest: "Wykonaj test",
		buyTest: "Kup test",
		downloadCertificate: "Pobierz certyfikat",
		send: "Wyślij",
		login: "Zaloguj się",
		changePassword: "Zmień hasło",
		register: "Zarejestruj się",
		seeApplicantData: "Zobacz materiały aplikanta",
		next: "Przejdź dalej",
		back: "Powrót",
		sendRegistration: "Wyślij zgłoszenie",
		returnToHomepage: "Przejdź do strony głównej",
		confirm: "Potwierdź",
		edit: "Edytuj",
		remove: "Usuń",
		download: "Pobierz",
		logout: "Wyloguj",
		yes: "Tak",
		no: "Nie",
		sendRequest: "Wyślij zgłoszenie",
      startConsultation: 'Rozpocznij',
	},
	profileImageComponent: {
		buttons: {
			change: "Zmień",
			add: "Dodaj",
		},
		srButtons: {
			edit: "Edytuj zdjęcie",
			add: "Dodaj zdjęcie",
			saveProfileImage: "Zatwierdź zmianę zdjęcia profilowego",
			cancelProfileImage: "Anuluj zmianę  zdjęcia profilowego",
      deleteProfileImage: 'Usuń zdjęcie profilowe',
			imageTooBig: "Zdjęcie profilowe musi mieć wielkość poniżej 1MB.",
		},
	},
    calendar: {
        title: "Twój grafik dostępności",
        buttons: {
          update: "Uaktualnij",
          delete: "Usuń",
          create: "Stwórz",
          populate: "Zapełnij",
          back: "Powrót",
          blockSlots: "Uaktualnij sloty"
        },
        calendarDetails: {
          title: "Detale kalendarza",
          form: {
            name: {
              label: "Calendar name",
              placeholder: "Name of calendar"
            } ,
            timezone: {
              label: "Strefa czasowa",
              placeholder: "Nie wybrano strefy",
              details: "Zmiana strefy czasowej jest możliwa tylko przed ustawieniem godzin dostępności"
            }
          }
        },
        calendarTimeSlots: {
          title: "Zakres godzin",
          form: {
              startHour: 'Godzina rozpoczęcia',
              lastHour: 'Ostatnia godzina',
              startHourPlaceholder: 'Wybierz godzinę rozpoczęcia',
              lastHourPlaceholder: 'Wybierz ostatnią godzinę pracy',
              duration: 'Czas trwania'
          },
          tabs: {
            week: {
              title: "Tydzień",
              timeSlots: "Zakres godzin",
              availableFrom: "Godzina rozpoczęcia",
              availableUntil: "Godzina zakończenia",
              endDate: "Data zakończenia",
              duration: "Czas trwania",
              slotsToPopulate: "Wybierz godziny dostępności:",
              details: "Zaznacz swoją tygodniową dostępność. Będzie ona automatycznie ustawiona w Twoim kalendarzu. Aby zmienić ustawienia konkretnych dat, przejdź do zakładki Kalendarz."
            },
            month: {
              title: "Miesiąc",
            },
            calendar: {
              title: "Kalendarz",
              editCalendar: "Kliknij na datę aby wybrać slot"
            },
          },
          weekDays: {
            monday: "Mon",
            tuesday: "Tue",
            wednesday: "Wed",
            thursday: "Thu",
            friday: "Fri",
            saturday: "Sat",
            sunday: "Sun"
          }
        },
        modals: {
          confirmCreateModal: {
            title: "Stwórz kalendarz",
            question: "Czy jestes pewien że chcesz stworzyć kalendarz?",
            success: "Tworzenie kalendarza zakończone sukcesem"
          },
          confirmUpdateModal: {
            title: "Uaktualnij kalendarz",
            question: "Czy na pewno chcesz nadpisać kalendarz?",
            success: "Uaktualniono kalendarz."
          },
          confirmDeleteModal: {
            title: "Usuń kalendarz",
            description: "Posiadasz wideo konsultacje przypisane do tego kalendarza. Nawet jeśli go usuniesz, konsultacje będą dostępne. Jeśli chcesz odwołać konsultacje musisz to zrobić ręcznie.",
            deleteBtn: "Usuń kalendarz",
            success: "Kalendarz usunięty."
          },
          confirmPopulateModal: {
            title: "Wypełnij kalendarz",
            question: "Czy na pewno chcesz wypełnić kalendarz?",
            success: "Wypełniono kalendarz",
            newTitle: "Potwierdź modyfikację zakresu godzin",
            content1: "Okres podlegający modyfikacji:",
            content2: "Od: Teraz + 24h",
            content3: "Do: Ostatni dzień bieżącego miesiąca",
            content4: "Wykryliśmy manualnie zmodyfikowane sloty w dniach podanych poniżej. Zaznacz daty, dla których sloty mają zostać zachowane",

          },
          confirmBlockSlotsModal: {
            title: "Potwierdź zmianę slotów dostępności",
            question: "Czy na pewno chcesz zmienić sloty dostępności?",
            success: "Sloty uaktualnione"
          },
          buttons: {
            yes: "tak",
            no: "nie"
          }
        }
      },
	formValidation: {
		errors: {
			minLength: "Wartość jest zbyt krótka",
			isEmailValid: "Adres email jest nieprawidłowy",
			isRequired: "Pole wymagane",
			isCheckedTrue: "Musi być zaznaczone",
			isTrue: "Musi być zaznaczone",
			isPasswordSame: "Hasła nie mogą być różne",
			isStartDateValid: "Data musi być w przyszłości",
			isStartDateGreaterOrEvenValid: "Data musi być w przyszłości lub dziś",
			isEndDateValid: "Data powrotu musi być po dacie wylotu",
			isNumber: "Wartość musi być liczbą",
			isPositiveNumberOrNull: "Wpisana wartość musi być dodatnia ",
		},
	},
  dateRangePicker: {
    config: {
      previousMonth: 'Poprzedni miesiac',
      lastWeek: 'Ostatnie 7 dni',
      today: 'Dziś',
      currentMonth: 'Obecny miesiąc'
    }
  },
  taskStatusTypes: {
    cancelled: 'Przerwane',
    during: 'W trakcie',
    not_completed: 'Nie zakończone',
    finished: 'Zakończone',
    new: 'Nowe',
    noStatus: 'Bez statusu'
  },
  gradeTypes: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    noStatus: 'nd'
  },
	country: {
		AFG: "Afganistan",
		ALB: "Albania",
		DZA: "Algieria",
		AND: "Andora",
		AGO: "Angola",
		AIA: "Anguilla",
		ATA: "Antarktyka",
		ATG: "Antigua i Barbuda",
		SAU: "Arabia Saudyjska",
		ARG: "Argentyna",
		ARM: "Armenia",
		ABW: "Aruba",
		AUS: "Australia",
		AUT: "Austria",
		AZE: "Azerbejdżan",
		BHS: "Bahamy",
		BHR: "Bahrajn",
		BGD: "Bangladesz",
		BRB: "Barbados",
		BEL: "Belgia",
		BLZ: "Belize",
		BEN: "Benin",
		BMU: "Bermudy",
		BTN: "Bhutan",
		BLR: "Białoruś",
		BOL: "Boliwia",
		BES: "Bonaire, Sint Eustatius i Saba",
		BIH: "Bośnia i Hercegowina",
		BWA: "Botswana",
		BRA: "Brazylia",
		BRN: "Brunei",
		IOT: "Brytyjskie Terytorium Oceanu Indyjskiego",
		VGB: "Brytyjskie Wyspy Dziewicze",
		BGR: "Bułgaria",
		BFA: "Burkina Faso",
		BDI: "Burundi",
		CHL: "Chile",
		CHN: "Chiny",
		HRV: "Chorwacja",
		CUW: "Curaçao",
		CYP: "Cypr",
		TCD: "Czad",
		MNE: "Czarnogóra",
		CZE: "Czechy",
		UMI: "Dalekie Wyspy Mniejsze Stanów Zjednoczonych",
		DNK: "Dania",
		COD: "Demokratyczna Republika Konga",
		DMA: "Dominika",
		DOM: "Dominikana",
		DJI: "Dżibuti",
		EGY: "Egipt",
		ECU: "Ekwador",
		ERI: "Erytrea",
		EST: "Estonia",
		ETH: "Etiopia",
		FLK: "Falklandy",
		FJI: "Fidżi",
		PHL: "Filipiny",
		FIN: "Finlandia",
		FRA: "Francja",
		ATF: "Francuskie Terytoria Południowe i Antarktyczne",
		GAB: "Gabon",
		GMB: "Gambia",
		SGS: "Georgia Południowa i Sandwich Południowy",
		GHA: "Ghana",
		GIB: "Gibraltar",
		GRC: "Grecja",
		GRD: "Grenada",
		GRL: "Grenlandia",
		GEO: "Gruzja",
		GUM: "Guam",
		GGY: "Guernsey",
		GUF: "Gujana Francuska",
		GUY: "Gujana",
		GLP: "Gwadelupa",
		GTM: "Gwatemala",
		GNB: "Gwinea Bissau",
		GNQ: "Gwinea Równikowa",
		GIN: "Gwinea",
		HTI: "Haiti",
		ESP: "Hiszpania",
		NLD: "Holandia",
		HND: "Honduras",
		HKG: "Hongkong",
		IND: "Indie",
		IDN: "Indonezja",
		IRQ: "Irak",
		IRN: "Iran",
		IRL: "Irlandia",
		ISL: "Islandia",
		ISR: "Izrael",
		JAM: "Jamajka",
		JPN: "Japonia",
		YEM: "Jemen",
		JEY: "Jersey",
		JOR: "Jordania",
		CYM: "Kajmany",
		KHM: "Kambodża",
		CMR: "Kamerun",
		CAN: "Kanada",
		QAT: "Katar",
		KAZ: "Kazachstan",
		KEN: "Kenia",
		KGZ: "Kirgistan",
		KIR: "Kiribati",
		COL: "Kolumbia",
		COM: "Komory",
		COG: "Kongo",
		KOR: "Korea Południowa",
		PRK: "Korea Północna",
		CRI: "Kostaryka",
		CUB: "Kuba",
		KWT: "Kuwejt",
		LAO: "Laos",
		LSO: "Lesotho",
		LBN: "Liban",
		LBR: "Liberia",
		LBY: "Libia",
		LIE: "Liechtenstein",
		LTU: "Litwa",
		LUX: "Luksemburg",
		LVA: "Łotwa",
		MKD: "Macedonia",
		MDG: "Madagaskar",
		MYT: "Majotta",
		MAC: "Makau",
		MWI: "Malawi",
		MDV: "Malediwy",
		MYS: "Malezja",
		MLI: "Mali",
		MLT: "Malta",
		MNP: "Mariany Północne",
		MAR: "Maroko",
		MTQ: "Martynika",
		MRT: "Mauretania",
		MUS: "Mauritius",
		MEX: "Meksyk",
		FSM: "Mikronezja",
		MMR: "Mjanma",
		MDA: "Mołdawia",
		MCO: "Monako",
		MNG: "Mongolia",
		MSR: "Montserrat",
		MOZ: "Mozambik",
		NAM: "Namibia",
		NRU: "Nauru",
		NPL: "Nepal",
		DEU: "Niemcy",
		NER: "Niger",
		NGA: "Nigeria",
		NIC: "Nikaragua",
		NIU: "Niue",
		NFK: "Norfolk",
		NOR: "Norwegia",
		NCL: "Nowa Kaledonia",
		NZL: "Nowa Zelandia",
		OMN: "Oman",
		PAK: "Pakistan",
		PLW: "Palau",
		PSE: "Palestyna",
		PAN: "Panama",
		PNG: "Papua-Nowa Gwinea",
		PRY: "Paragwaj",
		PER: "Peru",
		PCN: "Pitcairn",
		PYF: "Polinezja Francuska",
		POL: "Polska",
		PRI: "Portoryko",
		PRT: "Portugalia",
		ZAF: "Południowa Afryka",
		CAF: "Republika Środkowoafrykańska",
		CPV: "Republika Zielonego Przylądka",
		REU: "Reunion",
		RUS: "Rosja",
		ROU: "Rumunia",
		RWA: "Rwanda",
		ESH: "Sahara Zachodnia",
		KNA: "Saint Kitts i Nevis",
		LCA: "Saint Lucia",
		VCT: "Saint Vincent i Grenadyny",
		BLM: "Saint-Barthélemy",
		MAF: "Saint-Martin",
		SPM: "Saint-Pierre i Miquelon",
		SLV: "Salwador",
		ASM: "Samoa Amerykańskie",
		WSM: "Samoa",
		SMR: "San Marino",
		SEN: "Senegal",
		SRB: "Serbia",
		SYC: "Seszele",
		SLE: "Sierra Leone",
		SGP: "Singapur",
		SXM: "Sint Maarten",
		SVK: "Słowacja",
		SVN: "Słowenia",
		SOM: "Somalia",
		LKA: "Sri Lanka",
		USA: "Stany Zjednoczone",
		SWZ: "Eswatini",
		SDN: "Sudan",
		SSD: "Sudan Południowy",
		SUR: "Surinam",
		SJM: "Svalbard i Jan Mayen",
		SYR: "Syria",
		CHE: "Szwajcaria",
		SWE: "Szwecja",
		TJK: "Tadżykistan",
		THA: "Tajlandia",
		TWN: "Tajwan",
		TZA: "Tanzania",
		TLS: "Timor Wschodni",
		TGO: "Togo",
		TKL: "Tokelau",
		TON: "Tonga",
		TTO: "Trynidad i Tobago",
		TUN: "Tunezja",
		TUR: "Turcja",
		TKM: "Turkmenistan",
		TCA: "Turks i Caicos",
		TUV: "Tuvalu",
		UGA: "Uganda",
		UKR: "Ukraina",
		URY: "Urugwaj",
		UZB: "Uzbekistan",
		VUT: "Vanuatu",
		WLF: "Wallis i Futuna",
		VAT: "Watykan",
		VEN: "Wenezuela",
		HUN: "Węgry",
		GBR: "Wielka Brytania",
		VNM: "Wietnam",
		ITA: "Włochy",
		CIV: "Wybrzeże Kości Słoniowej",
		BVT: "Wyspa Bouveta",
		CXR: "Wyspa Bożego Narodzenia",
		IMN: "Wyspa Man",
		SHN: "Wyspa Świętej Heleny, Wyspa Wniebowstąpienia i Tristan da Cunha",
		ALA: "Wyspy Alandzkie",
		COK: "Wyspy Cooka",
		VIR: "Wyspy Dziewicze Stanów Zjednoczonych",
		HMD: "Wyspy Heard i McDonalda",
		CCK: "Wyspy Kokosowe",
		MHL: "Wyspy Marshalla",
		FRO: "Wyspy Owcze",
		SLB: "Wyspy Salomona",
		STP: "Wyspy Świętego Tomasza i Książęca",
		ZMB: "Zambia",
		ZWE: "Zimbabwe",
		ARE: "Zjednoczone Emiraty Arabskie",
	},
	language: {
		de: "niemiecki",
		pl: "polski",
		en: "angielski",
	},
    schoolStudyLevel: {
        bachelor: "Licencjat",
        master: "Magister",
    },
    viewTaskModal: {
        title: 'Zadanie: {{title}}',
        description: 'Opis zadania',
        attachments: 'Załączniki',
        deadline: 'Deadline',
        noContent: 'Zadanie nie posiada opisu',
        noAttachments: 'Do zadania nie są przypisane żadne załączniki',
        noDeadline: 'Nie wyznaczono terminu'
    },
    maintenance: {
        pageNotFound: 'Błąd - nie znaleziono strony'
    },
    alerts: {
        baseError: 'Coś poszło nie tak. Spróbuj ponownie później',
        noAccessError: "Nie posiadasz odpowiednich uprawnień aby zalogować się do panelu.",
        authError: "Niepoprawny login lub hasło. Sprawdź poprawność wpisanych danych i spróbuj jeszcze raz",
        loginError: "Podczas logowania wystąpił błąd:",
        tryAgain: "Spróbuj jeszcze raz.",
        tokenRefreshed: "Twoja sesja została odnowiona",
        unknown: "Wystąpił nieznany błąd."
    }
};

export default pl;
